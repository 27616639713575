import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Layout, SignUp } from '../../components';

const SignIssueQuestionPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <section className="py-5">
        <Container fluid="lg">
          <Row>
            <Col>
              <h1>Sign Up Issue or Question</h1>
              <SignUp.SignUpIssueForm />
            </Col>
          </Row>
        </Container>
      </section>
      <Layout.Footer />
    </>
  );
};

export default SignIssueQuestionPage;

export const Head = () => <Layout.PageHead subtitle="Sign Up Issue or Question" googleNoIndex />;
